import clsx from "clsx";
import "./faModalFooter.scss";

type FaModalFooterProps = {
    className?: string;
    children: React.ReactNode;
};

export function FaModalFooter(props: FaModalFooterProps) {
    return (
        <div
            className={clsx("fa-modal-footer", props.className)}
            onClick={event => {
                event.stopPropagation();
            }}>
            {props.children}
        </div>
    );
}
