import clsx from "clsx";
import "./faModalContent.scss";

type FaModalContentProps = {
    className?: string;

    children: React.ReactNode;
};

export function FaModalContent(props: FaModalContentProps) {
    return (
        <div
            className={clsx("fa-modal-content", props.className)}
            onClick={event => {
                event.stopPropagation();
            }}>
            {props.children}
        </div>
    );
}
