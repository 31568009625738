import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { useFaModalContext } from "@/components/FaModal/FaModalContext";
import "./faModalHeader.scss";

type FaModalHeaderProps = {
    children?: React.ReactNode;

    className?: string;
};

export function FaModalHeader(props: FaModalHeaderProps) {
    const { dismiss } = useFaModalContext();

    return (
        <div
            className={clsx("fa-modal-header", props.className)}
            onClick={event => {
                event.stopPropagation();
            }}>
            <Dialog.Title className="fa-modal-header__title">{props.children}</Dialog.Title>
            <button className="fa-modal-header__close" onClick={dismiss}>
                <FontAwesomeIcon icon={faXmark} />
            </button>
        </div>
    );
}
