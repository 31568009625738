import { isPlatform } from "@ionic/react";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { FaModalContext } from "@/components/FaModal/FaModalContext";
import "./faModalRoot.scss";

type FaModalRootProps = {
    isOpen: boolean;
    onDismiss?: () => void;

    className?: string;

    children: React.ReactNode;
};

export function FaModalRoot(props: FaModalRootProps) {
    const handleOpenChange = (open: boolean) => {
        if (!open) {
            props.onDismiss?.();
        }
    };

    const handleInteractOutside = (event: Event) => {
        const target = event.target;
        if (!(target instanceof HTMLElement)) {
            return;
        }

        const whitelistedTags = ["pwa-action-sheet", "pwa-camera-modal", "pwa-camera-modal-instance"];

        const isWhitelisted = whitelistedTags.some(tag => {
            return target.tagName === tag.toUpperCase();
        });
        if (isWhitelisted) {
            event.preventDefault();
            return;
        }
    };

    const handleEscapeKeyDown = (event: KeyboardEvent) => {
        if (!(event.target instanceof HTMLElement)) {
            return;
        }
        if (event.target.closest(".multi-select__dropdown")) {
            event.preventDefault();
            return;
        }
        if (event.target.closest(".single-select__dropdown")) {
            event.preventDefault();
            return;
        }
    };

    return (
        <FaModalContext.Provider value={{ dismiss: props.onDismiss }}>
            <Dialog.Root modal open={props.isOpen} onOpenChange={handleOpenChange}>
                <Dialog.Portal>
                    <Dialog.Overlay className="fa-modal-root__overlay" />
                    <Dialog.Content
                        data-fa-popover-target
                        className="fa-modal-root__content-container"
                        onInteractOutside={handleInteractOutside}
                        onEscapeKeyDown={handleEscapeKeyDown}
                        onClick={ev => ev.stopPropagation()}>
                        <div
                            className={clsx(
                                "fa-modal-root__content",
                                {
                                    "fa-modal-root__content--mobile": isPlatform("mobile"),
                                },
                                props.className
                            )}>
                            {props.children}
                        </div>
                    </Dialog.Content>
                </Dialog.Portal>
            </Dialog.Root>
        </FaModalContext.Provider>
    );
}
